import React, { useEffect, useState } from 'react';
import './Dashboard.scss';
import { text } from 'app/localization';

import { connect } from 'react-redux';
import { pageInfoActions, authActions } from 'app/redux/actions';

import { NAVIGATION } from 'config';

import { ScreenWithNavBar, Slider, MarkerCluster } from 'app/components';
import { Navigation, Autoplay } from 'swiper';

import {
    MapContainer,
    TileLayer,
	useMap,
	Marker,
	Popup,
	useMapEvent,
} from 'react-leaflet';

import * as L from "leaflet";

import { homepageService } from 'services';

import { Modal } from 'bootstrap';

const store_list = [
    {
        id: 1,
        name: 'LocaCafe Store #1',
        position: '154-156 Nguyễn Hữu Thọ, Phước Kiển, Nhà Bè, Thành phố Hồ Chí Minh',
        img: '/public_assets/images/home/store-2.jpg',
        lat: 10.714957704016596,
        long: 106.70440015406216
    },
    {
        id: 2,
        name: 'LocaCafe Store #2',
        position: '24 Nguyễn Công Hoan, Giảng Võ, Ba Đình, Hà Nội.',
        img: '/public_assets/images/home/store-3.jpg',
        lat: 21.027429025919318,
        long: 105.81374844097691
    },
    {
        id: 3,
        name: 'LocaCafe Store #3',
        position: 'Số 23 Sunrise E, KĐT The Manor Central Park, Đại Kim, Hoàng Mai, Hà Nội (Công viên Trung tâm The Manor Central Park Nguyễn Xiển)',
        img: '/public_assets/images/home/store-4.jpg',
        lat: 20.97145208430682,
        long: 105.812462210688
    },
    {
        id: 4,
        name: 'LocaCafe Store #4',
        position: 'Khu nhà ở thấp tầng TT3 lô số 32, Khu đô thị mới Thành Phố Giao Lưu, Từ Liêm, Hà Nội ',
        img: '/public_assets/images/home/store-1.jpg',
        lat: 21.05183988974107,
        long: 105.77705299636264
    },
    {
        id: 5,
        name: 'LocaCafe Store #5',
        position: '4/3c Khu Phố 2, Phường Tân Phong, Thành Phố Biên Hoà, Đồng Nai',
        img: '/public_assets/images/home/store-2.jpg',
        lat: 10.960615051708409,
        long: 106.8527221647512
    },
    {
        id: 6,
        name: 'LocaCafe Store #6',
        position: 'Khu Nhà Mới , Cấn Hữu, Quốc Oai, Hà Nội',
        img: '/public_assets/images/home/store-3.jpg',
        lat: 21.0415807,
        long: 105.7426738
    },
    {
        id: 7,
        name: 'LocaCafe Store #7',
        position: '02 Lô A15 Khu đô thị mới hiện đại phía đông Hòn Cặp Bè, phường Hồng Hải, Thành phố Hạ Long, tỉnh Quảng Ninh',
        img: '/public_assets/images/home/store-4.jpg',
        lat: 20.950375323172768,
        long: 107.09205418987145
    },
    {
        id: 8,
        name: 'LocaCafe Store #8',
        position: '301 Quốc Lộ 91b, Phường Long Tuyền, Bình Thuỷ, Cần Thơ',
        img: '/public_assets/images/home/store-1.jpg',
        lat: 10.03745721373833,
        long: 105.7434612867572
    },
    {
        id: 9,
        name: 'LocaCafe Store #9',
        position: '77 Nguyễn Hoàng, phường Kim Long, tp Huế',
        img: '/public_assets/images/home/store-2.jpg',
        lat: 16.46344417040173,
        long: 107.55464313753701
    },
    {
        id: 10,
        name: 'LocaCafe Store #10',
        position: 'Khu đấu giá Phương Canh ĐG1, Trịnh Văn Bô, Nam Từ Liêm',
        img: '/public_assets/images/home/store-3.jpg',
        lat: 21.03643055137933,
        long: 105.73614921009765
    }
]

const Dashboard = props => {
    const { history, location, pageInfo, logOut } = props;
    const lang = pageInfo.display_lang;

    const [storeCard, setStoreCard] = useState(store_list[0]);
    const [currentVideoPlayerId, setCurrentVideoPlayerId] = useState('C4_2YXILyCs');

    const header_items = [
        {
            id: 1,
            title: '1 chạm vạn món quà',
            subtitle: 'Quà vào tay - Tải App ngay!',
            description: `
                <b>&quot;Một chạm - Vạn món quà&quot;</b> là chương trình săn vũ trụ quà tặng <b>dành cho GenZ</b> tại các điểm săn quà trên toàn quốc chỉ với một ứng dụng điện thoại - LocaMos
            `,
        },
        {
            id: 2,
            title: 'Flexin trên LocaCafe',
            subtitle: 'Flex tại Loca, Nhận Quà Đỉnh',
            description: `
                <b>&quot;Flexin&apos; trên LocaCafe&quot;</b> là cuộc thi <b>check in - review</b> về trải nghiệm của bạn tại <b>10 điểm LocaCafe</b> trên toàn quốc
            `,
        },
        {
            id: 3,
            title: 'Cosplay Connect',
            subtitle: 'Gen-Z Kết Nối Thế hệ mới',
            description: `
                <b>&quot;Cosplay Connect&quot;</b> (gọi tắt là Cos-Con) là cuộc thi <b>Cosplay</b> dành cho cộng đồng Coser tại <b>LocaCafe</b>
            `,
        }
    ]

    function renderHeaderItem(item, index) {
        return (
            <div className="header-slide-item" key={'header_'+index}>
                <div className={`header-slide-item-wrapper header-slide-item-${item.id}`}>
                    <div className="header-globe globe-1"><img src="/public_assets/images/elements/section-1/globe-1.png" alt="" className="img-fluid"></img></div>
                    <div className="header-globe globe-2"><img src="/public_assets/images/elements/section-1/globe-2.png" alt="" className="img-fluid"></img></div>
                    <div className="header-globe globe-3"><img src="/public_assets/images/elements/section-1/globe-3.png" alt="" className="img-fluid"></img></div>
                    <div className="row align-items-center">
                        <div className="col-md-5 text-center">
                            <h1 className="header-slide-item-title">
                                <span>{item.title}</span>
                                <img className="img-fluid w-100" src={`/public_assets/images/elements/section-1/slide-${item.id}/title.png`} alt=""></img>
                            </h1>
                            <p className="header-slide-item-subtitle">{item.subtitle}</p>
                            <img className="img-fluid w-100 mobile" src={`/public_assets/images/elements/section-1/slide-${item.id}/slide-img.png`} alt=""></img>
                            {/* <a href="#join" className="btn btn-primary btn-genz btn-190 btn-header-join mobile">THAM GIA NGAY</a> */}
                            {
                                item.id === 1
                                    ? <a href="#join" className="btn btn-primary btn-genz btn-190 mobile" data-bs-toggle="modal" data-bs-target="#flexinFlyerModal">THAM GIA NGAY</a>
                                    : <a href="#join" className="btn btn-primary btn-genz btn-190 mobile">THAM GIA NGAY</a>
                            }
                            <p className="header-slide-item-description" dangerouslySetInnerHTML={{ __html: item.description }}></p>
                            {/* <a href="#join" className="btn btn-primary btn-genz btn-190 desktop">THAM GIA NGAY</a> */}
                            {
                                item.id === 1
                                    ? <a href="#join" className="btn btn-primary btn-genz btn-190 desktop" data-bs-toggle="modal" data-bs-target="#flexinFlyerModal">THAM GIA NGAY</a>
                                    : <a href="#join" className="btn btn-primary btn-genz btn-190 desktop">THAM GIA NGAY</a>
                            }
                        </div>
                        <div className="col-md-7 desktop">
                            <img className="img-fluid w-100" src={`/public_assets/images/elements/section-1/slide-${item.id}/slide-img.png`} alt=""></img>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function renderStoreList(list) {
        if (!list || list.length === 0) return;

        let team_members = [];
        list.map((item, index) => {
            team_members.push(
                <div className="col-6 col-md-2" key={'store_item_' + index}>
                    <div className="store-item">
                        <h5>Cơ sở {item.id}</h5>
                        <p>{item.position}</p>
                    </div>
                </div>
            )
            return item;
        });

        return team_members;
    }

    function renderStoreListSlideItem(item, index) {
        return (
            <div className="store-item">
                <h5>Cơ sở {item.id}</h5>
                <p>{item.position}</p>
            </div>
        )
    }

    const guide_video = [
        {
            video_id: 'C4_2YXILyCs'
        },
        {
            video_id: 'Jw_3zKVkGHY'
        },
        {
            video_id: 'Gx81sb5Pw1I'
        },
        {
            video_id: 'mPMYIEYyo08'
        },
        {
            video_id: '4tYh9cKjSVM'
        },
        {
            video_id: 'suHOv4JSasg'
        },
        {
            video_id: 'HQOlBWGV9No'
        },
        {
            video_id: '-4xDj7csRr4'
        },
    ]

    function renderVideoPlaylist(list) {
        if (!list || list.lenth === 0) return null;

        var playlist = [];
        list.forEach((item, index) => {
            playlist.push(
                <div className="video-playlist-item" key={'pressvideo' + item.video_id + '_' + index} onClick={() => { setCurrentVideoPlayerId(item.video_id + '?autoplay=1') }}>
                    <img src={`https://i.ytimg.com/vi/${item.video_id}/hqdefault.jpg`} alt="" width={190} height={120}></img>
                </div>
            )
        })

        return playlist;
    }

    const gift_list = [
        {
            "id": "1",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0000_MocKhoa_CTThaQua_GenZ_13.jpg "
        },
        {
            "id": "2",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0001_MocKhoa_CTThaQua_GenZ_12.jpg "
        },
        {
            "id": "3",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0002_MocKhoa_CTThaQua_GenZ_11.jpg "
        },
        {
            "id": "4",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0003_MocKhoa_CTThaQua_GenZ_10.jpg "
        },
        {
            "id": "5",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0004_MocKhoa_CTThaQua_GenZ_09.jpg "
        },
        {
            "id": "6",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0005_MocKhoa_CTThaQua_GenZ_08.jpg "
        },
        {
            "id": "7",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0006_MocKhoa_CTThaQua_GenZ_07.jpg "
        },
        {
            "id": "8",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0007_MocKhoa_CTThaQua_GenZ_06.jpg "
        },
        {
            "id": "9",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0008_MocKhoa_CTThaQua_GenZ_05.jpg "
        },
        {
            "id": "10",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0009_MocKhoa_CTThaQua_GenZ_04.jpg "
        },
        {
            "id": "11",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0010_MocKhoa_CTThaQua_GenZ_03.jpg "
        },
        {
            "id": "12",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0011_MocKhoa_CTThaQua_GenZ_02.jpg "
        },
        {
            "id": "13",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0012_MocKhoa_CTThaQua_GenZ_01.jpg "
        },
        {
            "id": "14",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0013_VatPhamLe_CTThaQua_GenZVCSieuThiGo_CTThaQua_GenZ.jpg "
        },
        {
            "id": "15",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0014_VatPhamLe_CTThaQua_GenZVCLocaCafe_CTThaQua_GenZ.jpg "
        },
        {
            "id": "16",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0015_VatPhamLe_CTThaQua_GenZVCCircleK_CTThaQua_GenZ.jpg "
        },
        {
            "id": "17",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0016_VatPhamLe_CTThaQua_GenZCombo2_CTThaQua_GenZ.jpg "
        },
        {
            "id": "18",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0017_VatPhamLe_CTThaQua_GenZCombo1_CTThaQua_GenZ.jpg "
        },
        {
            "id": "19",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0018_VatPhamLe_CTThaQua_GenZButLocaMos_CTThaQua_GenZ.jpg "
        },
        {
            "id": "20",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0019_TuiTote_CTThaQua_GenZ_18.jpg "
        },
        {
            "id": "21",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0020_TuiTote_CTThaQua_GenZ_17.jpg "
        },
        {
            "id": "22",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0021_TuiTote_CTThaQua_GenZ_16.jpg "
        },
        {
            "id": "23",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0022_TuiTote_CTThaQua_GenZ_15.jpg "
        },
        {
            "id": "24",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0023_TuiTote_CTThaQua_GenZ_14.jpg "
        },
        {
            "id": "25",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0024_TuiTote_CTThaQua_GenZ_13.jpg "
        },
        {
            "id": "26",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0025_TuiTote_CTThaQua_GenZ_12.jpg "
        },
        {
            "id": "27",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0026_TuiTote_CTThaQua_GenZ_11.jpg "
        },
        {
            "id": "28",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0027_TuiTote_CTThaQua_GenZ_10.jpg "
        },
        {
            "id": "29",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0028_TuiTote_CTThaQua_GenZ_09.jpg "
        },
        {
            "id": "30",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0029_TuiTote_CTThaQua_GenZ_08.jpg "
        },
        {
            "id": "31",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0030_TuiTote_CTThaQua_GenZ_07.jpg "
        },
        {
            "id": "32",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0031_TuiTote_CTThaQua_GenZ_06.jpg "
        },
        {
            "id": "33",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0032_TuiTote_CTThaQua_GenZ_05.jpg "
        },
        {
            "id": "34",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0033_TuiTote_CTThaQua_GenZ_04.jpg "
        },
        {
            "id": "35",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0034_TuiTote_CTThaQua_GenZ_03.jpg "
        },
        {
            "id": "36",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0035_TuiTote_CTThaQua_GenZ_02.jpg "
        },
        {
            "id": "37",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0036_TuiTote_CTThaQua_GenZ_01.jpg "
        },
        {
            "id": "38",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0037_Ly12ConGiap_CTThaQua_Ty.jpg"
        },
        {
            "id": "39",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0038_Ly12ConGiap_CTThaQua_Tuat.jpg "
        },
        {
            "id": "40",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0039_Ly12ConGiap_CTThaQua_Ti.jpg "
        },
        {
            "id": "41",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0040_Ly12ConGiap_CTThaQua_Thin.jpg "
        },
        {
            "id": "42",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0041_Ly12ConGiap_CTThaQua_Than.jpg "
        },
        {
            "id": "43",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0042_Ly12ConGiap_CTThaQua_Suu.jpg "
        },
        {
            "id": "44",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0043_Ly12ConGiap_CTThaQua_Ngo.jpg "
        },
        {
            "id": "45",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0044_Ly12ConGiap_CTThaQua_Mui.jpg "
        },
        {
            "id": "46",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0045_Ly12ConGiap_CTThaQua_Mao.jpg "
        },
        {
            "id": "47",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0046_Ly12ConGiap_CTThaQua_Dau.jpg "
        },
        {
            "id": "48",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0047_Ly12ConGiap_CTThaQua_Dan.jpg "
        },
        {
            "id": "49",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0048_GoiChuU_CTThaQua_01GoiChuU_CTThaQua_16.jpg "
        },
        {
            "id": "50",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0049_GoiChuU_CTThaQua_01GoiChuU_CTThaQua_15.jpg "
        },
        {
            "id": "51",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0050_GoiChuU_CTThaQua_01GoiChuU_CTThaQua_14.jpg "
        },
        {
            "id": "52",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0051_GoiChuU_CTThaQua_01GoiChuU_CTThaQua_13.jpg "
        },
        {
            "id": "53",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0052_GoiChuU_CTThaQua_01GoiChuU_CTThaQua_12.jpg "
        },
        {
            "id": "54",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0053_GoiChuU_CTThaQua_01GoiChuU_CTThaQua_11.jpg "
        },
        {
            "id": "55",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0054_GoiChuU_CTThaQua_01GoiChuU_CTThaQua_10.jpg "
        },
        {
            "id": "56",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0055_GoiChuU_CTThaQua_01GoiChuU_CTThaQua_09.jpg "
        },
        {
            "id": "57",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0056_GoiChuU_CTThaQua_01GoiChuU_CTThaQua_08.jpg"
        },
        {
            "id": "58",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0057_GoiChuU_CTThaQua_01GoiChuU_CTThaQua_07.jpg "
        },
        {
            "id": "59",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0058_GoiChuU_CTThaQua_01GoiChuU_CTThaQua_06.jpg "
        },
        {
            "id": "60",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0059_GoiChuU_CTThaQua_01GoiChuU_CTThaQua_05.jpg "
        },
        {
            "id": "61",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0060_GoiChuU_CTThaQua_01GoiChuU_CTThaQua_04.jpg "
        },
        {
            "id": "62",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0061_GoiChuU_CTThaQua_01GoiChuU_CTThaQua_03.jpg "
        },
        {
            "id": "63",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0062_GoiChuU_CTThaQua_01GoiChuU_CTThaQua_02.jpg "
        },
        {
            "id": "64",
            "img_url": "/public_assets/images/elements/section-2/GoiChuU_CTThaQua_01GoiChuU_CTThaQua_01_0063_Layer 0.jpg"
        }
    ]

    function renderGiftItem(item, index) {
        return (
            <div className="gift-item">
                <div className="ratio ratio-1x1">
                    <img src={item.img_url} alt="" className="img-fluid w-100"></img>
                </div>
            </div>
        )
    }

    return (
        <ScreenWithNavBar>
            <section id="welcome" className="page-section welcome-section" style={{ backgroundImage: 'url(/public_assets/images/home-bg.jpg)' }}>
                <div className="container-xl header-content">
                    <div className="header-slider">
                        <Slider
                            list={header_items}
                            renderItem={renderHeaderItem}
                            overrideConfig={{
                                // pagination={{
                                //     clickable: true,
                                // }},
                                loop: true,
                                modules: [Autoplay, Navigation],
                                slidesPerView: 1,
                                autoplay: {
                                    delay: 2500,
                                    disableOnInteraction: true
                                },
                                navigation: true
                            }}
                        />
                    </div>
                </div>
            </section>
            <div className="section-bg-wrapper">
            <section id="gift" className="page-section gift-section">
                <div className="gift-section-bg">
                    <div className="row justify-content-end">
                        <div className="col-md-6 gift-section-bg-img" style={{ backgroundImage: 'url(/public_assets/images/elements/section-2/background/gift-bg.jpg)' }}></div>
                    </div>
                </div>
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <h2 className="sec-title text-end">
                            Vũ trụ quà tặng<br /> 
                            chờ đón
                            <img src="/public_assets/images/elements/section-2/sec-header-element.png" alt="" className="img-fluid title-element-1"></img>
                            {/* <img src="/public_assets/images/elements/section-2/sec-header-element-2.svg" alt="" className="img-fluid title-element-2"></img> */}
                        </h2>
                    </div>
                    <div className="sec-content">
                        <div className="gift-slider">
                            <Slider
                                list={gift_list}
                                renderItem={renderGiftItem}
                                overrideConfig={{
                                    modules: [Autoplay],
                                    slidesPerView: 3,
                                    autoplay: {
                                        delay: 2500,
                                        disableOnInteraction: true
                                    },
                                    loop: true,
                                    breakpoints: {
                                        576: {
                                            slidesPerView: 6
                                        },
                                        992: {
                                            slidesPerView: 8
                                        }
                                    },
                                    spaceBetween: 0
                                }}
                            />
                        </div>
                        <div className="row">
                            <div className="col-sm-6 col-lg-3 desktop">
                                <div className="gift-quotes text-center mt-4">
                                    <p className="mb-0">
                                        Dù bạn đi một mình, đi có đôi,<br />
                                        hay đi cùng đồng đội,...<br />
                                        thì ai cũng sẽ có quà</p>
                                    <img className="gift-quotes-element" src="/public_assets/images/elements/section-2/slide-text.png"></img>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-9 px-0">
                                <div>
                                    <Slider
                                        list={gift_list}
                                        renderItem={renderGiftItem}
                                        overrideConfig={{
                                            modules: [Autoplay],
                                            slidesPerView: 3,
                                            initialSlide: 3,
                                            autoplay: {
                                                delay: 2500,
                                                disableOnInteraction: true
                                            },
                                            loop: true,
                                            breakpoints: {
                                                576: {
                                                    slidesPerView: 3
                                                },
                                                992: {
                                                    slidesPerView: 6,
                                                    initialSlide: 6,
                                                }
                                            },
                                            spaceBetween: 0,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="gift-slider mb-4 mb-md-0">
                            <Slider
                                list={gift_list}
                                renderItem={renderGiftItem}
                                overrideConfig={{
                                    modules: [Autoplay],
                                    slidesPerView: 3,
                                    initialSlide: 6,
                                    autoplay: {
                                        delay: 2500,
                                        disableOnInteraction: true
                                    },
                                    loop: true,
                                    breakpoints: {
                                        576: {
                                            slidesPerView: 6,
                                            initialSlide: 9,
                                        },
                                        992: {
                                            slidesPerView: 8,
                                            initialSlide: 14,
                                        }
                                    },
                                    spaceBetween: 0
                                }}
                            />
                        </div>
                        <div className="gift-slider desktop">
                            <Slider
                                list={gift_list}
                                renderItem={renderGiftItem}
                                overrideConfig={{
                                    modules: [Autoplay],
                                    slidesPerView: 3,
                                    initialSlide: 9,
                                    autoplay: {
                                        delay: 2500,
                                        disableOnInteraction: true
                                    },
                                    loop: true,
                                    breakpoints: {
                                        576: {
                                            slidesPerView: 6,
                                            initialSlide: 15,
                                        },
                                        992: {
                                            slidesPerView: 8,
                                            initialSlide: 22,
                                        }
                                    },
                                    spaceBetween: 0
                                }}
                            />
                        </div>
                        <div className="gift-quotes text-center mobile">
                            <p className="mb-0">
                                Dù bạn đi một mình, đi có đôi,<br />
                                hay đi cùng đồng đội,...<br />
                                thì ai cũng sẽ có quà</p>
                            <img className="gift-quotes-element" src="/public_assets/images/elements/section-2/slide-text.png"></img>
                        </div>
                    </div>
                </div>
            </section>
            <section id="store" className="page-section store-section">
                <div className="store-section-bg">
                    <div className="row">
                        <div className="col-md-6 store-section-bg-img" style={{ backgroundImage: 'url(/public_assets/images/elements/section-3/background/store-bg.jpg)' }}></div>
                    </div>
                </div>
                <div className="container-xl">
                    <div className="sec-header text-center mobile">
                        <div className="row align-items-center">
                            <div className="col-md-5 text-center text-md-end">
                                <h2 className="sec-title mb-4 ms-3 ms-md-0">
                                    Khám phá<br />LocaCafe
                                    <img src="/public_assets/images/elements/section-3/sec-header-element.svg" alt="" className="img-fluid title-element-1"></img>
                                </h2>
                            </div>
                            <div className="col-md-7">
                                <h6 className="sec-description">Tới <span className="text-info">LocaCafe</span> để đổi quà và trải nghiệm đồ uống <br className="desktop" />& Công nghệ Thế hệ mới</h6>
                            </div>
                        </div>
                    </div>
                    <div className="sec-content">
                        <div className="row justify-content-around desktop">
                            { renderStoreList(store_list.slice(0, 5)) }
                            <div className="col-12">
                                <div className="sec-header text-center desktop">
                                    <div className="row align-items-center">
                                        <div className="col-md-5 text-end">
                                            <h2 className="sec-title">
                                                Khám phá<br />LocaCafe
                                                <img src="/public_assets/images/elements/section-3/sec-header-element.svg" alt="" className="img-fluid title-element-1"></img>
                                            </h2>
                                        </div>
                                        <div className="col-md-7">
                                            <h6 className="sec-description">Tới <span className="text-info">LocaCafe</span> để đổi quà và trải nghiệm đồ uống <br className="desktop" />& Công nghệ Thế hệ mới</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            { renderStoreList(store_list.slice(5, 10)) }
                        </div>
                        <div className="store-list-slider mobile">
                            <div className="row">
                                { renderStoreList(store_list) }
                            </div>
                            {/* <Slider
                                list={store_list}
                                renderItem={renderStoreListSlideItem}
                                overrideConfig={{
                                    modules: [Autoplay],
                                    slidesPerView: 1,
                                    centeredSlides: true,
                                    centerInsufficientSlides: true,
                                    autoplay: {
                                        delay: 2500,
                                        disableOnInteraction: true
                                    }
                                }}
                            /> */}
                        </div>
                    </div>
                </div>
            </section>
            <section id="contest" className="page-section contest-section">
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <h2 className="sec-title">
                            <span>LocaCafe&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><br /><span>Gen Z</span>
                            <img src="/public_assets/images/elements/section-4/sec-header-element-1.svg" alt="" className="img-fluid title-element-1"></img>
                            <img src="/public_assets/images/elements/section-4/sec-header-element-2.svg" alt="" className="img-fluid title-element-2"></img>
                            <img src="/public_assets/images/elements/section-1/globe-2.png" alt="" className="img-fluid title-element-3"></img>
                        </h2>
                        <p className="sec-description">LocaCafe mong muốn đem lại nhiều chương trình Kết nối Thế hệ mới để trở thành Trung tâm gặp gỡ - Meeting Central - dành cho thế hệ trẻ</p>
                    </div>
                    <div className="sec-content">
                        <div className="row justify-content-center">
                            <div className="col-md-4 col-xl-3">
                                <div className="contest-item mb-5">
                                    <div className="contest-item-img">
                                        <img className="img-fluid w-100 mb-2 mb-md-4" src="/public_assets/images/elements/section-4/Poster_CosplayConnect.jpg" alt=""></img>
                                    </div>
                                    <button className="btn btn-primary btn-genz btn-190" data-bs-toggle="modal" data-bs-target="#cosConModal">TÌM HIỂU NGAY</button>
                                </div>
                            </div>
                            <div className="col-1 desktop"></div>
                            <div className="col-md-4 col-xl-3">
                                <div className="contest-item">
                                    <div className="contest-item-img">
                                        <img className="img-fluid w-100 mb-2 mb-md-4" src="/public_assets/images/elements/section-4/Poster_FlexinTrenLocaCafe.jpg" alt=""></img>
                                    </div>
                                    <button className="btn btn-primary btn-genz btn-190" data-bs-toggle="modal" data-bs-target="#flexinModal">TÌM HIỂU NGAY</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </div>
            <section id="video" className="page-section video-section">
                <div className="container-xl">
                    <div className="row align-items-center">
                        <div className="col-md-7 order-2 order-md-1">
                            <div className="video-player mb-3 mb-md-4">
                                <div className="ratio ratio-16x9">
                                    <iframe src={`https://www.youtube.com/embed/${currentVideoPlayerId}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen={true}></iframe>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 order-1 order-md-2">
                            <div className="sec-header text-end">
                                <h2 className="sec-title">Cẩm nang <br className="desktop" />tân thủ</h2>
                                <p className="sec-description">Hãy theo dõi những video dưới đây <br className="desktop" />để trở thành &quot;Săn thủ&quot; cừ khôi nhất</p>
                            </div>
                        </div>
                        <div className="col-12 order-3">
                            <div className="video-playlist mb-3 mb-md-0">
                                { renderVideoPlaylist(guide_video) }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="join" className="page-section join-section">
                <div className="container-xl">
                    <div className="join-section-wrapper">
                        <div className="row justify-content-around align-items-center">
                            <div className="col-md-6">
                                <div className="sec-header mb-md-0">
                                    <h2 className="sec-title">
                                        <span className="text-info">Trở thành đại sứ</span><br />
                                        <img src="/public_assets/images/elements/section-6/sec-header-element-1.svg" alt="" className="img-fluid title-element-1"></img>Gen Z<img src="/public_assets/images/elements/section-6/sec-header-element-2.svg" alt="" className="img-fluid title-element-2"></img><br />
                                        LocaCafe
                                    </h2>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdyY1gXBPOoz4QENlcmrnUSk78a-0BXAY6TatlWpF9eIm9YHA/viewform?embedded=true" width="100%" height="500" frameBorder="0">Loading…</iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="social" className="page-section social-section">
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <h2 className="sec-title">THEO DÕI CHƯƠNG TRÌNH <br className="desktop" />QUA CÁC TRANG MẠNG XÃ HỘI</h2>
                    </div>
                    <div className="sec-content text-center">
                        <div className="d-flex flex-row justify-content-center">
                            <a href="https://tiktok.com" target="_blank" className="me-5"><img className="img-fluid" src="/public_assets/images/elements/section-7/tiktok-icon.svg" width={105} height={105}></img></a>
                            <a href="https://facebook.com" target="_blank"><img className="img-fluid" src="/public_assets/images/elements/section-7/facebook-icon.svg" width={105} height={105}></img></a>
                        </div>
                    </div>
                </div>
            </section>
            <div className="page-background-sticky" style={{ backgroundImage: 'url(/public_assets/images/elements/section-6/background/join-bg.jpg)' }}></div>

            {/* cosConModal */}
            <div className="modal fade cos-con-modal" id="cosConModal" tabIndex="-1" aria-labelledby="cosConModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row align-items-end mb-5">
                                <div className="col-md-4 order-2 order-md-1">
                                    <img className="img-fluid w-100 mb-2" src="/public_assets/images/elements/section-4/cos-con-1.png"></img>
                                </div>
                                <div className="col-md-8 order-1 order-md-2">
                                    <h2>Cách tham gia</h2>
                                    <table className="table table-borderless table-light mb-0">
                                        <tbody>
                                            <tr>
                                                <td>BƯỚC 1</td>
                                                <td>Chuẩn bị trang phục, đạo cụ… và tới LocaCafe cùng đồng bọn (từ 3 người trở lên), cùng người thương (2 người), hoặc một mình (không sao cả) để Cosplay và trải nghiệm không gian cà phê công nghệ</td>
                                            </tr>
                                            <tr>
                                                <td>BƯỚC 2</td>
                                                <td>Tự do sáng tạo nội dung bằng cách quay video, chụp hình… tại các góc check in có background, Logo, đồ uống… của LocaCafe</td>
                                            </tr>
                                            <tr>
                                                <td>BƯỚC 3</td>
                                                <td>
                                                    Đăng hình ảnh/video review lên các tài khoản Mạng xã hội bạn có và: Thêm vị trí tại LocaCafe bạn check-in khi đăng bài; Tag nửa kia (nếu bạn đi cùng người thương) hoặc team của bạn (nếu bạn đi cùng đồng bọn). Nếu bạn đi một mình thì đừng ngại tag LocaCafe làm bạn đồng hành nhé!<br />
                                                    Gắn kèm Hashtag của chương trình để chúng mình theo dõi được bài thi của bạn nè: <span className="text-primary">#LocaCafe #CosplayConnect #LocaMos #Review</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>BƯỚC 4</td>
                                                <td>Kêu gọi tương tác tự nhiên (không dùng tool hoặc hack) đến hết 24h ngày 22/10; Tương tác được tính là: Like/React, lượt share, comment tích cực.</td>
                                            </tr>
                                            <tr>
                                                <td>BƯỚC 5</td>
                                                <td>Gắn link bài post và ID tài khoản của bạn trong app LocaMos vào link biểu mẫu tại Landing page … để LocaCafe xác nhận bạn đã đăng ký dự thi thành công!</td>
                                            </tr>
                                            <tr>
                                                <td>BƯỚC 6</td>
                                                <td>Khi kết thúc chương trình, BTC sẽ tổng kết số lượng tương tác tự nhiên trên mọi kênh bạn đã đăng để xét giải thưởng, bạn chỉ cần đợi kết quả và nhận thưởng thôi!</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-8">
                                    <h2>Thể lệ</h2>
                                    <p>
                                        <span className="text-primary">BÀI THI HỢP LỆ</span><br />
                                    </p>
                                    <p>
                                        Bài thi hợp lệ là bài thi đảm bảo các tiêu chí sau:</p>
                                    <ol>
                                        <li>Bài đăng ở chế độ Công khai;</li>
                                        <li>Có hình ảnh/video… phù hợp thuần phong mỹ tục Việt Nam;</li>
                                        <li>Bài đăng đã gắn Thêm vị trí LocaCafe;</li>
                                        <li>Tag đủ bạn bè nếu bạn đi theo nhóm từ 3 người trở lên hoặc đi cùng người thương;</li>
                                        <li>Có đủ hashtag của chương trình: <span className="text-primary">#LocaCafe #CosplayConnect #LocaMos #Review</span>;</li>
                                        <li>Toàn bộ tương tác của bài đăng là tự nhiên (không dùng tool hoặc hack);</li>
                                        <li>Đã đăng ký dự thi theo link: <a className="text-primary" href="https://genz.locamos.vn">https://genz.locamos.vn</a></li>
                                    </ol>
                                    <p>
                                        <span className="text-primary">CÁCH TÍNH ĐIỂM</span><br />
                                    </p>
                                    <ul>
                                        <li>1 Like/React tích cực (tim, thương, haha, wow) = 1 điểm;</li>
                                        <li>1 comment = 3 điểm;</li>
                                        <li>1 share = 5 điểm.</li>
                                    </ul>
                                    <p>Kết thúc thời gian dự thi, BTC sẽ tính tổng tương tác tự nhiên tại tất cả các kênh của bài đăng dự thi. Các cấp độ giải sẽ được tính từ cao tới thấp.</p>
                                </div>
                                <div className="col-md-4">
                                    <img className="img-fluid w-100" src="/public_assets/images/elements/section-4/cos-con-2.png"></img>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-center border-0 pb-4">
                            <a href="#join" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#cosConModal">ĐĂNG KÝ NGAY</a>
                        </div>
                    </div>
                </div>
            </div>

            {/* flexinModal */}
            <div className="modal fade cos-con-modal" id="flexinModal" tabIndex="-1" aria-labelledby="flexinModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row align-items-center mb-5">
                                <div className="col-md-4 order-2 order-md-1">
                                    <img className="img-fluid w-100 mb-2" src="/public_assets/images/elements/section-4/flexin-1.png"></img>
                                </div>
                                <div className="col-md-8 order-1 order-md-2">
                                    <h2>Cách tham gia</h2>
                                    <table className="table table-borderless table-light mb-md-0">
                                        <tbody>
                                            <tr>
                                                <td>BƯỚC 1</td>
                                                <td>Tới LocaCafe cùng đồng bọn (từ 3 người trở lên), cùng người thương (2 người), hoặc một mình (không sao cả) để trải nghiệm;</td>
                                            </tr>
                                            <tr>
                                                <td>BƯỚC 2</td>
                                                <td>
                                                    Tham gia Check - in và Review bằng 1 trong 2 cách sau:<br />
                                                    - Chụp ảnh check in tại các góc check in có background, Logo, đồ uống… của LocaCafe;<br />
                                                    - Quay video nêu cảm nhận của bạn về Không gian, đồ uống, trải nghiệm công nghệ miễn phí với kính VR… hoặc bất kỳ điều gì bạn thấy thú vị tại LocaCafe;
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>BƯỚC 3</td>
                                                <td>
                                                Đăng hình ảnh/video review lên các tài khoản Mạng xã hội bạn có và:
                                                - Thêm vị trí tại LocaCafe bạn check-in khi đăng bài;
                                                - Tag nửa kia (nếu bạn đi cùng người thương) hoặc team của bạn (nếu bạn đi cùng đồng bọn). Nếu bạn đi một mình thì đừng ngại tag LocaCafe làm bạn đồng hành nhé!;
                                                - Nội dung bài đăng của bạn có thể chính là nội dung review - hoặc bạn có thể chỉ cần ghi một dòng ngắn gọn như: “Không gian gặp gỡ thế hệ mới ở LocaCafe, anh em đã đến thử chưa??”;
                                                - Gắn kèm Hashtag của chương trình để chúng mình theo dõi được bài thi của bạn nè: <span className="text-primary">#LocaCafe #CosplayConnect #LocaMos #Review</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>BƯỚC 4</td>
                                                <td>
                                                Kêu gọi tương tác tự nhiên (không dùng tool hoặc hack) đến hết 24h ngày 22/11;<br />
                                                Tương tác được tính là: Like/React, lượt share, comment tích cực.
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>BƯỚC 5</td>
                                                <td>Gắn link bài post và ID tài khoản của bạn trong app LocaMos vào form đăng ký tại <a href="https://genz.locamos.vn">genz.locamos.vn</a> để LocaCafe xác nhận bạn đã đăng ký dự thi thành công!</td>
                                            </tr>
                                            <tr>
                                                <td>BƯỚC 6</td>
                                                <td>Khi kết thúc chương trình, BTC sẽ tổng kết số lượng tương tác tự nhiên trên mọi kênh bạn đã đăng để xét giải thưởng, bạn chỉ cần đợi kết quả và nhận thưởng thôi!</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-md-8">
                                    <h2>Thể lệ</h2>
                                    <p>
                                        <span className="text-primary">BÀI THI HỢP LỆ</span><br />
                                    </p>
                                    <p>
                                        Bài thi hợp lệ là bài thi đảm bảo các tiêu chí sau:</p>
                                    <ol>
                                        <li>Bài đăng ở chế độ Công khai;</li>
                                        <li>Có hình ảnh/video, nội dung review (tích cực, giới thiệu, quảng bá);</li>
                                        <li>Bài đăng đã gắn Thêm vị trí LocaCafe;</li>
                                        <li>Tag đủ bạn bè nếu bạn đi theo nhóm từ 3 người trở lên hoặc đi cùng người thương;</li>
                                        <li>Có đủ hashtag của chương trình: <span className="text-primary">#LocaCafe #CosplayConnect #LocaMos #Review</span>;</li>
                                        <li>Toàn bộ tương tác của bài đăng là tự nhiên (không dùng tool hoặc hack);</li>
                                        <li>Đã đăng ký dự thi theo link: <a className="text-primary" href="https://genz.locamos.vn">https://genz.locamos.vn</a></li>
                                    </ol>
                                    <p>
                                        <span className="text-primary">CÁCH TÍNH ĐIỂM</span><br />
                                    </p>
                                    <ul>
                                        <li>1 Like/React tích cực (tim, thương, haha, wow) = 1 điểm;</li>
                                        <li>1 comment = 3 điểm;</li>
                                        <li>1 share = 5 điểm.</li>
                                    </ul>
                                    <p>Kết thúc thời gian dự thi, BTC sẽ tính tổng tương tác tự nhiên tại tất cả các kênh của bài đăng dự thi. Các cấp độ giải sẽ được tính từ cao tới thấp.</p>
                                </div>
                                <div className="col-md-4">
                                    <img className="img-fluid w-100" src="/public_assets/images/elements/section-4/flexin-2.png"></img>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-center border-0 pb-4">
                            <a href="#join" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#flexinModal">ĐĂNG KÝ NGAY</a>
                        </div>
                    </div>
                </div>
            </div>

            {/* flexinFlyerModal */}
            <div className="modal fade cos-con-modal" id="flexinFlyerModal" tabIndex="-1" aria-labelledby="flexinFlyerModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-0">
                            <img className="img-fluid w-100 mb-3" src="/public_assets/images/elements/section-1/slide-1/flyer-1.jpg"></img>
                            <img className="img-fluid w-100" src="/public_assets/images/elements/section-1/slide-1/flyer-2.jpg"></img>
                        </div>
                        <div className="modal-footer justify-content-center border-0">
                            <a href="#join" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#flexinFlyerModal">ĐĂNG KÝ NGAY</a>
                        </div>
                    </div>
                </div>
            </div>
        </ScreenWithNavBar>
    )
}

const mapStateToProps = (state) => {
    const { pageInfo } = state;
    return { pageInfo }
}

const actionCreators = {
    logOut: authActions.logout
}

export default connect(mapStateToProps, actionCreators)(Dashboard);